// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/plan-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".plan[data-v-53f759c0]{padding-top:64px}.plan .plan-header[data-v-53f759c0]{width:100%;height:507px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover;display:flex;justify-content:center;align-items:center;text-align:center;color:#fff}.plan .plan-header .title[data-v-53f759c0]{font-size:38px}.plan .plan-header .info[data-v-53f759c0]{margin-top:20px;font-size:24px}.plan .plan-content[data-v-53f759c0]{margin-top:80px}.plan .plan-content .title[data-v-53f759c0]{text-align:center;font-size:38px}.plan .plan-content .plan-list-box[data-v-53f759c0]{width:1200px;margin:0 auto;padding-top:80px;padding-bottom:30px;display:flex;justify-content:space-around}.plan .plan-content .plan-list-box .plan-list[data-v-53f759c0]{width:120px}.plan .plan-content .plan-list-box .plan-list .list-item[data-v-53f759c0]{border-left:2px solid #797979;color:#797979;text-align:center;padding:10px 0;cursor:pointer}.plan .plan-content .plan-list-box .plan-list .list-item.active[data-v-53f759c0]{border-left:2px solid #000;color:#000}.plan .plan-content .plan-list-box .plan-info[data-v-53f759c0]{width:900px}.plan .plan-content .plan-list-box .plan-info .info-item[data-v-53f759c0]{width:100%;height:400px;background-position:50%;background-repeat:no-repeat;background-size:cover;margin-top:30px;cursor:pointer;display:flex;justify-content:center;align-items:center;color:#fff;text-align:center}.plan .plan-content .plan-list-box .plan-info .info-item .info[data-v-53f759c0]{font-size:18px;margin-top:10px}.plan .plan-content .plan-list-box .plan-info .info-item .more[data-v-53f759c0]{margin-top:30px}.plan .plan-content .plan-list-box .plan-info .info-item[data-v-53f759c0]:first-of-type{margin-top:0}", ""]);
// Exports
module.exports = exports;
